/** 
  All of the routes for the  Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

//  Dashboard React layouts
import Dashboard from "layouts/dashboard";
import ServeSafeRecord from "layouts/tables";
import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";

//  Dashboard React icons

import { BsFillPersonFill } from "react-icons/bs";
import { BsCreditCardFill } from "react-icons/bs";
import { IoStatsChart } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { MdOutlineTableRows } from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { HiMiniUserGroup } from "react-icons/hi2";
import { LuChefHat } from "react-icons/lu";

import ProtectGuard from "./protectGuard";
import AuthGuard from "./authGuard";
import GetPopupData from "layouts/popup";
import GetGuestData from "layouts/guest";
import GetReservationData from "layouts/reservation";
import GetChefData from "layouts/chef";

const routes = [
  {
    id: 1,
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <IoHome size="15px" color="inherit" />,
    component: AuthGuard(Dashboard),
    noCollapse: true,
  },
  {
    id: 2,
    type: "collapse",
    name: "Serve Safe Record",
    key: "tables",
    route: "/serve-safe-record",
    icon: <MdOutlineTableRows size="15px" color="inherit" />,
    component: AuthGuard(ServeSafeRecord),
    noCollapse: true,
  },
  {
    id: 3,
    type: "collapse",
    name: "Popup",
    key: "popup",
    route: "/popup",
    icon: <IoStatsChart size="15px" color="inherit" />,
    component: AuthGuard(GetPopupData),
    noCollapse: true,
  },
  {
    id: 4,
    type: "collapse",
    name: "Guest",
    key: "guest",
    route: "/guest",
    icon: <FaRegUserCircle size="15px" color="inherit" />,
    component: AuthGuard(GetGuestData),
    noCollapse: true,
  },
  {
    id: 9,
    type: "collapse",
    name: "Reservation",
    key: "reservation",
    route: "/reservation",
    icon: <HiMiniUserGroup size="15px" color="inherit" />,
    component: AuthGuard(GetReservationData),
    noCollapse: true,
  },
  {
    id: 10,
    type: "collapse",
    name: "Chef",
    key: "chef",
    route: "/chef",
    icon: <LuChefHat size="15px" color="inherit" />,
    component: AuthGuard(GetChefData),
    noCollapse: true,
  },
  // {
  //   id: 9,
  //   type: "collapse",
  //   name: "Reservation",
  //   key: "guest",
  //   route: "/guest",
  //   icon: <FaRegUserCircle size="15px" color="inherit" />,
  //   component: AuthGuard(GetGuestData),
  //   noCollapse: true,
  // },
  {
    id: 5,
    type: "collapse",
    name: "Billing",
    key: "billing",
    route: "/billing",
    icon: <BsCreditCardFill size="15px" color="inherit" />,
    component: AuthGuard(Billing),
    noCollapse: true,
  },
  { id: 6, type: "title", title: "Account Pages", key: "account-pages" },
  {
    id: 7,
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <BsFillPersonFill size="15px" color="inherit" />,
    component: AuthGuard(Profile),
    noCollapse: true,
  },
  {
    id: 8,
    // type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    // icon: <IoIosDocument size="15px" color="inherit" />,
    component: ProtectGuard(SignIn),
    // noCollapse: true,
  },
];

export default routes;
