import http from "./http.service";
import { getDispatch } from "utils/dispatch.util";
import { loadersActions } from "store/slices/loader.slice";
import { guestDataActions } from "store/slices/guestData.slice";

const GuestData = {
  async getGuestData({ currentPage, searchBy = "" }) {
    const dispatch = getDispatch();
    dispatch(loadersActions.setLoadingGuestData(true));
    const [res, error] = await http.get(`admin/guest?search=${searchBy}&page=${currentPage}`);
    dispatch(loadersActions.setLoadingGuestData(false));
    if (res?.data?.success === true) {
      dispatch(guestDataActions.setGuestData(res?.data));
    }
    return [res, error];
  },
};

export default GuestData;
