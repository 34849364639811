import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import authSlice from "./slices/auth.slice";
import loadersSlice from "./slices/loader.slice";
import serveSafeSlice from "./slices/serveSafe.slice";
import popupData from "./slices/popupData.slice";
import guestData from "./slices/guestData.slice";
import reservationData from "./slices/reserData.slice";
import chefData from "./slices/chefData.slice";

const combineReducer = combineReducers({
  authSlice: authSlice,
  loaders: loadersSlice,
  serveSafe: serveSafeSlice,
  popupData: popupData,
  guestData: guestData,
  reservationData: reservationData,
  chefData: chefData,
});

const store = configureStore({
  reducer: combineReducer,
});

export default store;
