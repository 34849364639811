import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// Page imports
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, useFormik } from "formik";
import { CircularProgress, FormHelperText } from "@mui/material";

//  Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import VuiSwitch from "components/VuiSwitch";
import GradientBorder from "examples/GradientBorder";

//  Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
// import bgSignIn from "assets/images/signInImage.png";
import bgSignIn from "assets/images/sign_in_img.svg";
import AuthService from "../../../services/auth.service";

function SignIn() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { isLoadingLogin } = useSelector((state) => state.loaders);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format")
      .required("Required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  const onSubmit = async (values) => {
    const data = {
      email: values.email,
      password: values.password,
    };
    await AuthService.signIn(data, history);
  };

  const formdata = () => {
    return (
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
            <>
              <VuiBox component="form" role="form">
                {loading ? (
                  <>
                    <VuiBox
                      // container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="30vh"
                    >
                      <CircularProgress />
                    </VuiBox>
                  </>
                ) : (
                  <>
                    <VuiBox mb={2}>
                      <VuiBox mb={1} ml={0.5}>
                        <VuiTypography
                          component="label"
                          variant="button"
                          color="white"
                          fontWeight="medium"
                        >
                          Email
                        </VuiTypography>
                      </VuiBox>
                      <GradientBorder
                        minWidth="100%"
                        padding="1px"
                        borderRadius={borders.borderRadius.lg}
                        backgroundImage={radialGradient(
                          palette.gradients.borderLight.main,
                          palette.gradients.borderLight.state,
                          palette.gradients.borderLight.angle
                        )}
                      >
                        <VuiInput
                          type="email"
                          placeholder="Your email..."
                          fontWeight="500"
                          onChange={handleChange("email")}
                          onBlur={handleBlur("email")}
                          value={values.email}
                          // autoComplete={false}
                          error={errors.email && touched.email ? true : false}
                          // helperText={errors.email && touched.email ? errors.email : null}
                        />
                      </GradientBorder>
                      <FormHelperText error={errors.email && touched.email ? errors.email : false}>
                        {errors.email && touched.email ? errors.email : null}
                      </FormHelperText>
                    </VuiBox>
                    <VuiBox mb={2}>
                      <VuiBox mb={1} ml={0.5}>
                        <VuiTypography
                          component="label"
                          variant="button"
                          color="white"
                          fontWeight="medium"
                        >
                          Password
                        </VuiTypography>
                      </VuiBox>
                      <GradientBorder
                        minWidth="100%"
                        borderRadius={borders.borderRadius.lg}
                        padding="1px"
                        backgroundImage={radialGradient(
                          palette.gradients.borderLight.main,
                          palette.gradients.borderLight.state,
                          palette.gradients.borderLight.angle
                        )}
                      >
                        <VuiInput
                          type="password"
                          placeholder="Your password..."
                          sx={({ typography: { size } }) => ({
                            fontSize: size.sm,
                          })}
                          id="login-password"
                          name="password"
                          onChange={handleChange("password")}
                          onBlur={handleBlur("password")}
                          // autoComplete={false}
                          value={values.password}
                          error={errors.password && touched.password ? true : false}
                          // helperText={errors.password && touched.password ? errors.password : null}
                        />
                      </GradientBorder>
                      <FormHelperText
                        error={errors.password && touched.password ? errors.password : false}
                      >
                        {errors.password && touched.password ? errors.password : false}
                      </FormHelperText>
                    </VuiBox>
                    {/* <VuiBox mt={0.5}>
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        <VuiTypography
                          component={Link}
                          to="/forgot-password"
                          variant="button"
                          color="white"
                          fontWeight="regular"
                        >
                          Forgot Password
                        </VuiTypography>
                      </VuiTypography>
                    </VuiBox> */}
                  </>
                )}

                <VuiBox mt={4} mb={1}>
                  <VuiButton
                    disabled={isLoadingLogin ? true : false}
                    color="info"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    {isLoadingLogin ? "Loading..." : "SIGN IN"}
                  </VuiButton>
                </VuiBox>
              </VuiBox>
            </>
          )}
        </Formik>
      </>
    );
  };

  return (
    <CoverLayout
      title="Nice to see you!"
      color="white"
      description="Enter your email and password to sign in"
      image={bgSignIn}
    >
      {formdata()}
    </CoverLayout>
  );
}

export default SignIn;
