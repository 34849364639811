import http from "./http.service";
import { getDispatch } from "utils/dispatch.util";
import { loadersActions } from "store/slices/loader.slice";
import { popupDataActions } from "store/slices/popupData.slice";

const PopupData = {
  async getPopupData({ currentPage, searchBy = "" }) {
    const dispatch = getDispatch();
    dispatch(loadersActions.setLoadingPopupData(true));
    const [res, error] = await http.get(`/admin/popup?search=${searchBy}&page=${currentPage}`);
    dispatch(loadersActions.setLoadingPopupData(false));
    if (res?.data?.success === true) {
      dispatch(popupDataActions.setPopupData(res?.data));
    }
    return [res, error];
  },

  async updateBannedPopup({ banned, id }) {
    const dispatch = getDispatch();
    dispatch(loadersActions.setLoadingBannedPopupStatus({ id, loading: true }));
    const [res, error] = await http.patch(`/admin/popup/${id}`, { banned });
    dispatch(loadersActions.setLoadingBannedPopupStatus({ id, loading: false }));

    return [res, error];
  },
};

export default PopupData;
