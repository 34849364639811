"use client";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  chefData: null,
};

const chefDataSlice = createSlice({
  name: "chef",
  initialState,
  reducers: {
    setChefData: (state, action) => {
      state.chefData = action.payload;
    },
  },
});

export const chefDataActions = chefDataSlice.actions;
export default chefDataSlice.reducer;
