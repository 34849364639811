"use client";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  admin: null,
  logoutButtonToggle: false,
};

const authSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    logout: (state, action) => {},
    setAdmin: (state, action) => {
      state.admin = action.payload;
    },
  },
});

export const adminActions = authSlice.actions;
export default authSlice.reducer;
