import React from "react";
import { Redirect } from "react-router-dom";
const AuthGuard = (Element) => {
  return (props) => {
    const token = localStorage.getItem("token");

    if (token) {
      return <Element {...props} />;
    } else {
      return <Redirect to="/authentication/sign-in" {...props} />;
    }
  };
};

export default AuthGuard;
