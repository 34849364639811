import { getDispatch } from "../utils/dispatch.util";
import http from "./http.service";
import { loadersActions } from "../store/slices/loader.slice";
import { adminActions } from "../store/slices/auth.slice";

const AuthService = {
  //   sign in chef
  async signIn(data, history) {
    const dispatch = getDispatch();
    dispatch(loadersActions.setLoadingLogin(true));
    const [res, error] = await http.post("/admin/sign-in", data);
    dispatch(loadersActions.setLoadingLogin(false));
    if (res?.data?.success === true) {
      const { admin } = res?.data;
      dispatch(adminActions.setAdmin(admin));
      history.push("/dashbaord");
    }

    return [res, error];
  },
};

export default AuthService;
