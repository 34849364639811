"use client";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  popupData: null,
};

const popupDataSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    setPopupData: (state, action) => {
      state.popupData = action.payload;
    },
  },
});

export const popupDataActions = popupDataSlice.actions;
export default popupDataSlice.reducer;
