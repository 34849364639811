"use client";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  serveSafeRecord: null,
};

const serveSafeSlice = createSlice({
  name: "serveSafe",
  initialState,
  reducers: {
    setServeSafeRecord: (state, action) => {
      state.serveSafeRecord = action.payload;
    },
  },
});

export const serveSafeActions = serveSafeSlice.actions;
export default serveSafeSlice.reducer;
