"use client";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  guestData: null,
};

const guestDataSlice = createSlice({
  name: "guest",
  initialState,
  reducers: {
    setGuestData: (state, action) => {
      state.guestData = action.payload;
    },
  },
});

export const guestDataActions = guestDataSlice.actions;
export default guestDataSlice.reducer;
