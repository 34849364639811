"use client";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  reservationData: null,
};

const reserDataSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    setReservationData: (state, action) => {
      state.reservationData = action.payload;
    },
  },
});

export const reserDataActions = reserDataSlice.actions;
export default reserDataSlice.reducer;
