export default {
  columns: [
    { id: 1, name: "#", align: "left" },
    { id: 2, name: "Guest", align: "left" },
    { id: 3, name: "phone", align: "center" },
    { id: 4, name: "Email Verified", align: "center" },
    { id: 5, name: "Phone Verified", align: "center" },
    { id: 6, name: "Deleted", align: "center" },
    { id: 7, name: "created at", align: "center" },
    { id: 8, name: "updated at", align: "center" },
    // { id: 7, name: "fullName", align: "center" },
  ],
};
