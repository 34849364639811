export default {
  columns: [
    { id: 1, name: "#", align: "left" },
    { id: 2, name: "Chef", align: "left" },
    { id: 3, name: "status", align: "center" },
    { id: 4, name: "state", align: "center" },
    { id: 5, name: "serve Safe", align: "center" },
    { id: 6, name: "created at", align: "center" },
    { id: 7, name: "updated at", align: "center" },
  ],
};
