import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

//  Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

//  Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { CircularProgress, Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import guestDataCol from "./data/guestDataCol";
import Table from "examples/Tables/guest";
import GuestData from "services/guest.service";
import VuiInput from "components/VuiInput";

function GetGuestData() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchBy, setSearchBy] = useState("");
  const { isLoadingGuest } = useSelector((state) => state.loaders);
  const guestData = useSelector((state) => state.guestData?.guestData);

  const { columns } = guestDataCol;

  const totalPages = Math.ceil(guestData?.totalCount / 50);

  useEffect(() => {
    GuestData.getGuestData({ currentPage });
  }, [currentPage]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    document.documentElement.scrollTop = 0;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      GuestData.getGuestData({ currentPage: 1, searchBy });
      setCurrentPage(1);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Guest
              </VuiTypography>
              <VuiInput
                id="search-input"
                name="search-input"
                placeholder="Type here..."
                onChange={(e) => setSearchBy(e.target.value)}
                onKeyPress={handleKeyPress}
                icon={{ component: "search", direction: "left" }}
                sx={({ breakpoints }) => ({
                  [breakpoints.down("sm")]: {
                    maxWidth: "80px",
                  },
                  [breakpoints.only("sm")]: {
                    maxWidth: "80px",
                  },
                  backgroundColor: "info.main !important",
                })}
                // value={sea}
              />
            </VuiBox>
            {isLoadingGuest === true ? (
              <VuiBox
                // container
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="60vh"
              >
                <CircularProgress />
              </VuiBox>
            ) : (
              <VuiBox
                sx={{
                  "& th": {
                    borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                      `${borderWidth[1]} solid ${grey[700]}`,
                  },
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      backgroundColor: "red",
                      borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                        `${borderWidth[1]} solid ${grey[700]}`,
                    },
                  },
                  "& .MuiTableRow-root td": {
                    backgroundColor: "transparent !important",
                    color: "white !important",
                  },
                }}
              >
                <Table columns={columns} rows={guestData?.guests} currentPage={currentPage} />
              </VuiBox>
            )}
          </Card>
        </VuiBox>
        {isLoadingGuest === true ? (
          ""
        ) : (
          <VuiBox display="flex" justifyContent="center" alignItems="center">
            <Pagination
              sx={{
                ".Mui-selected ": {
                  background: `#0075ff !important`,
                },
              }}
              // siblingCount={3}
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          </VuiBox>
        )}
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default GetGuestData;
