import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Table as MuiTable,
  Select,
  TableCell,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";

//  Dashboard React components
import VuiBox from "components/VuiBox";
import VuiAvatar from "components/VuiAvatar";
import VuiTypography from "components/VuiTypography";

//  Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

import VuiBadge from "components/VuiBadge";
import ChefData from "services/chef.service";

function Author({ image, name, email }) {
  return (
    <VuiBox display="flex" alignItems="left">
      <VuiBox mr={1}>
        <VuiAvatar src={image} alt={name} size="sm" variant="rounded" />
      </VuiBox>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {name}
        </VuiTypography>
        <VuiTypography variant="caption" color="text">
          {email}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}
const Row = ({ row, index, currentPage }) => {
  const loading = useSelector((state) => state.loaders.isLoadingBannedChefStatus[row?._id]);
  const [chefBanned, setChefBanned] = useState(row?.banned || false);
  const id = row?._id;
  useEffect(() => {
    setChefBanned(row?.banned);
  }, [row?.banned]);

  const handleChange = async (event) => {
    setChefBanned(event.target.value);
    const [success] = await ChefData.updateBannedChef({ banned: event.target.value, id });
    if (!success || !success?.data?.success) setChefBanned(chefBanned);
  };
  console.log({ chefBanned }, "chefBanned");
  let backgroundColor = !chefBanned ? "#e31a1a !important" : "#01b574 !important";

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 }, padding: 0 }}>
      <TableCell size={"small"} key={row?._id} component="td">
        {(currentPage - 1) * 50 + index + 1}
      </TableCell>
      <TableCell padding="none" size={"small"} key={row?._id} component="td">
        <Author name={row?.firstName + " " + row?.lastName} email={row?.email} image={row?.image} />
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.phone || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <Tooltip title={row?.description} placement="top">
          <VuiTypography
            variant="h6"
            textTransform="capitalize"
            align="left"
            color="white"
            fontWeight="medium"
            width="350px"
            noWrap
          >
            {row?.description || "--"}
          </VuiTypography>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Tooltip title={row?.aboutChef} placement="top">
          <VuiTypography
            variant="h6"
            textTransform="capitalize"
            align="left"
            color="white"
            fontWeight="medium"
            width="350px"
            noWrap
          >
            {row?.aboutChef || "--"}
          </VuiTypography>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.currentPopupsPerMonth >= 0 ? row?.currentPopupsPerMonth : "0"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.popupGoalsPerMonth >= 0 ? row?.popupGoalsPerMonth : "0"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.lastOnboardingStage || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <Tooltip title={row?.typicalHostingDateAndTime} placement="top">
          <VuiTypography
            variant="h6"
            textTransform="capitalize"
            align="center"
            color="white"
            fontWeight="medium"
            width="350px"
            noWrap
          >
            {row?.typicalHostingDateAndTime || "--"}
          </VuiTypography>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        {row?.isEmailVerified ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {row?.isPhoneVerified ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {row?.paymentVerified ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {row?.isPremium ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {row?.isDeleted ? (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;Yes&nbsp;"
            color="success"
            size="md"
            container
            sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
              background: success.main,
              border: `${borderWidth[1]} solid ${success.main}`,
              borderRadius: borderRadius.md,
              color: white.main,
            })}
          />
        ) : (
          <VuiBadge
            variant="standard"
            badgeContent="&nbsp;&nbsp;No&nbsp;&nbsp;"
            size="md"
            container
            sx={({ palette: { white, error }, borders: { borderRadius, borderWidth } }) => ({
              background: error.main,
              border: `${borderWidth[1]} solid ${error.main}`,
              borderRadius: borderRadius.md,
            })}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {!loading ? (
          <FormControl
            variant="outlined"
            size="small"
            sx={{
              ".MuiInputBase-formControl": {
                color: "white !important",
                backgroundColor,
              },
            }}
          >
            <Select value={chefBanned || false} onChange={handleChange}>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <VuiBox display="flex" justifyContent="center" alignItems="center" minHeight="2vh">
            <CircularProgress />
          </VuiBox>
        )}
      </TableCell>
      <TableCell align="center">
        <VuiTypography variant="h6" align="center" color="white" fontWeight="medium" noWrap>
          {row?.facebookAccountLink || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography variant="h6" align="center" color="white" fontWeight="medium" noWrap>
          {row?.instagramAccountLink || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography variant="h6" align="center" color="white" fontWeight="medium" noWrap>
          {row?.tiktokAccountLink || "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.birthday ? moment(row?.birthday).format("MM/DD/YYYY") : "--"}
        </VuiTypography>
      </TableCell>
      <TableCell align="center">
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
          noWrap
        >
          {row?.pronouns || "--"}
        </VuiTypography>
      </TableCell>

      <TableCell size={"small"}>
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
        >
          {row?.createdAt ? moment(row?.createdAt).format("MM/DD/YYYY") : ""}
        </VuiTypography>
      </TableCell>
      <TableCell size={"small"}>
        <VuiTypography
          variant="h6"
          textTransform="capitalize"
          align="center"
          color="white"
          fontWeight="medium"
        >
          {row?.updatedAt ? moment(row?.updatedAt).format("MM/DD/YYYY") : ""}
        </VuiTypography>
      </TableCell>
    </TableRow>
  );
};
function Table({ columns, rows = [], currentPage }) {
  const { grey } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const renderColumns = columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <VuiBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="text"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
      >
        {name.toUpperCase()}
      </VuiBox>
    );
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <VuiBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </VuiBox>
          <TableBody>
            {rows?.map((row, index) => (
              <Row key={row?._id} row={row} index={index} currentPage={currentPage} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
}

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
