export default {
  columns: [
    { id: 1, name: "#", align: "left" },
    { id: 2, name: "color", align: "center" },
    { id: 3, name: "thumbnail", align: "center" },
    { id: 4, name: "name", align: "left" },
    { id: 5, name: "Chef", align: "left" },
    { id: 6, name: "description", align: "center" },
    { id: 7, name: "phone", align: "center" },
    { id: 8, name: "Popup Detail", align: "center" },
    { id: 9, name: "website", align: "center" },
    { id: 10, name: "Deleted", align: "center" },
    { id: 11, name: "published", align: "center" },
    { id: 12, name: "banned", align: "center" },
    { id: 13, name: "Repeat Popup Interest", align: "center" },
    { id: 14, name: "No Of Seats", align: "center" },
    { id: 15, name: "cost Per Seat", align: "center" },
    { id: 16, name: "seating location", align: "center" },
    { id: 17, name: "seating types", align: "center" },
    { id: 18, name: "dining experience", align: "center" },
    { id: 19, name: "popup images", align: "center" },
    { id: 20, name: "Facebook Account Link", align: "center" },
    { id: 21, name: "Instagram Account Link", align: "center" },
    { id: 22, name: "Tiktok Account Link", align: "center" },
    { id: 23, name: "created at", align: "center" },
    { id: 24, name: "updated at", align: "center" },
    // { id: 18, name: "firstName", align: "center" },
    // { id: 19, name: "lastName", align: "center" },
    // { id: 20, name: "email", align: "center" },
  ],
};
