export default {
  columns: [
    { id: 1, name: "#", align: "left" },
    { id: 2, name: "guest Details", align: "left" },
    { id: 3, name: "phone", align: "center" },
    { id: 4, name: "venue Slot", align: "center" },
    { id: 5, name: "no Of Guests", align: "center" },
    { id: 6, name: "amount", align: "center" },
    { id: 7, name: "invited Guest Details", align: "center" },
    { id: 8, name: "status", align: "center" },
    { id: 9, name: "Deleted", align: "center" },
    { id: 10, name: "venue Date", align: "center" },
    { id: 11, name: "created At", align: "center" },
    { id: 12, name: "updatedAt", align: "center" },
    // { id: 7, name: "fullName", align: "center" },
  ],
};
